export default {
  //"Empleo": "FC47C9",
  "Economía": "FB2D11",
  "Seguridad": "F7A334",
  "Educación": "E9F92A",
  "Ambiente": "32F991",
  "Salud": "4BD6F7", 
  "Democracia": "9F47FC",
  //"Vivienda y transporte": "FC47C9"
};

